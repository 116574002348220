<template>
  <v-container fluid>
    <SecondaryNavigation />
    <v-row>
      <v-col>
        <div class="text-h6">
          Trainer's Dashboard
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <ProfileCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-col>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
            dolorem sapiente! Consectetur adipisci quis corrupti officia,
            laboriosam at, esse blanditiis architecto eligendi incidunt, fuga
            doloremque? Quae aperiam est quia ducimus.
          </v-col>
          <v-col>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
            dolorem sapiente! Consectetur adipisci quis corrupti officia,
            laboriosam at, esse blanditiis architecto eligendi incidunt, fuga
            doloremque? Quae aperiam est quia ducimus.
          </v-col>
            <v-col>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
            dolorem sapiente! Consectetur adipisci quis corrupti officia,
            laboriosam at, esse blanditiis architecto eligendi incidunt, fuga
            doloremque? Quae aperiam est quia ducimus.
          </v-col>
          <v-col>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum,
            dolorem sapiente! Consectetur adipisci quis corrupti officia,
            laboriosam at, esse blanditiis architecto eligendi incidunt, fuga
            doloremque? Quae aperiam est quia ducimus.
          </v-col>
                    
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "../../../components/ProfileCard/ProfileCard.vue";
export default {
  name: "DashBoard",
  components: {
    SecondaryNavigation,
    ProfileCard
  }
};
</script>

<style></style>
